import React, { useState, useEffect } from 'react';
import '../../assets/css/auth.css';
import Logo from '../../assets/images/logo.svg'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      window.location.replace('/admin/');
    } else {
      setLoading(false);
    }
  }, []);

  const onSubmit = e => {
    e.preventDefault();

    const user = {
      username: username,
      password: password
    };

    fetch('https://api.sportplatzgarage.ch/v1/auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(data => {
        if (data.key) {
          localStorage.clear();
          localStorage.setItem('token', data.key);
          window.location.replace('/admin/');
        } else {
          setUsername('');
          setPassword('');
          localStorage.clear();
          setErrors(true);
        }
      });
  };

  return (
    <div className="authenticate-back">
      <span></span>
      <div className="authenticate-center">
        <span></span>
        <div className="authenticate-window">
          <div className="authenticate-logo">
            <img className="authenticate-logo-image" src={Logo} alt="Logo" />
          </div>
          {loading === false && <div className="authenticate-title">Login</div> }
          {errors === true && <div className="authenticate-error">Es kann mit den gebenen Daten nicht eingeloggt werden.</div>}
          {loading === false && (
            <form onSubmit={onSubmit}>
              <div className="authenticate-placeholder"></div>
              <TextField
                margin="dense"
                id="username"
                label="Benutzername"
                type="username"
                fullWidth
                variant="outlined"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <div className="authenticate-placeholder"></div>
              <TextField
                margin="dense"
                id="password"
                label="Passwort"
                type="password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <div className="authenticate-placeholder"></div>
              <div className="authenticate-buttons">
                <span></span>
                <span></span>
                <Button type="submit" variant="contained" color="default">
                  Login
                </Button>
              </div>
              <div className="authenticate-placeholder"></div>
            </form>
          )}
        </div>
        <span></span>
      </div>
      <span></span>
    </div>
  );
};

export default Login;
