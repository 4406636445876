import React from 'react';
import SecuredZone from '../SecuredZone/SecuredZone';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import linkState from 'linkstate';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import TextField from '@material-ui/core/TextField';
import API from '../../../api/api';
import uuid from "uuid";
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import OpeningHours from "../../../components/OpeningHours/OpeningHours";

const garageApi = new API();

class AdminSite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingDialog: false,
      update: true,
      web: {
        unikey: uuid.v4(),
        homeWelcomeTitle: 'Wilkommen',
        homeWelcomeText: 'Text',
        contactText:'Text',
        footerText:'Text',
        partnerText:'Text',
        garageLocation:'0,0',
        appointmentSuggestionText:'Text',
        appointmentSummaryText:'Text',
        appointmentThanksText:'Text',
        garageAboutText:'Text',
        impressumText:'Text',
        mapsLink:'Text',
        mapsLinkText:'Text',
        popUpcontactText:'Text',
        dataProtectionText:'Text',
        homeWriter:'Hello,World',
        homeImage:null,
        servicesImage:null,
        newsImage:null,
        appointmentImage:null,
        dataProtectionImage:null,
        impressumImage:null,
        openingHours: {}
      },
      homeImage:'Hochladen',
      servicesImage:'Hochladen',
      newsImage:'Hochladen',
      appointmentImage: 'Hochladen',
      dataProtectionImage:'Hochladen',
      impressumImage:'Hochladen',
      modules: {
          toolbar: {
            container: [
              [{ 'header': [1, 2, 3, false] }],
              ['bold', 'italic', 'underline','strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
              [{ 'align': [] }],
              ['link'],
              ['clean'],
             ],
          },
        },
      modulesImage: {
          toolbar: {
            container: [
              [{ 'header': [1, 2, 3, false] }],
              ['bold', 'italic', 'underline','strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
              [{ 'align': [] }],
              ['link','image'],
              ['clean'],
             ],
             handlers: {
               image: this.editorUploadImage.bind(this)
             },
          },
        },
    };

    this.days = [
        { key: "mon", name: "Montag" },
        { key: "tue", name: "Dienstag" },
        { key: "wed", name: "Mittwoch" },
        { key: "thu", name: "Donnerstag" },
        { key: "fri", name: "Freitag" },
        { key: "sat", name: "Samstag" },
        { key: "sun", name: "Sonntag" }
      ];

    this.fieldName = "location[working_hours]";

    this.partnerImage = React.createRef();
  }

  componentDidMount() {
    this.getConfig();
  }

  updateSelect(key,values) {
    // eslint-disable-next-line
    this.state.web.openingHours[key] = values;
  }

  editorUploadImage() {

    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () =>
    {
      var file = input.files[0];
      var formData = new FormData();
      // Update the formData object
      formData.append("image", file);
      formData.append('type', 'default');
      formData.append('name', 'Service-Image');
      formData.append('unikey', uuid.v4());

      garageApi.endpoints.image.create(formData)
        .then((getRequest) => {
          try
          {
            var rangeIndex = 0;
            var range = this.partnerImage.current.getEditorSelection();
            if(range)
            {
              rangeIndex = range.index;
            }

            this.partnerImage.current.getEditor().insertEmbed(rangeIndex, 'image', getRequest.data.image['full']);
          }
          catch(e)
          {
            console.log(e);
          }
        });
    };
  }

  getConfig(create = true)
  {
    garageApi.endpoints.site.getAll()
      .then((getRequest) => {
        try
        {
          if(Object.keys(getRequest.data.results).length === 0)
          {
            if(create) {
              this.createConfig();
            }
            else {
              console.log('error');
            }
          } else {

            try {
              getRequest.data.results[0].openingHours = JSON.parse(getRequest.data.results[0].openingHours);
            } catch (e)
            {

            }

            console.log(getRequest.data.results[0].openingHours);
            this.setState({ web: getRequest.data.results[0] })
            this.setState({ loading: false });
          }

        }
        catch(e)
        {
          console.log('error');
        }
      });
  }

  createConfig()
  {
    garageApi.endpoints.site.create(this.state.web)
      .then((getRequest) => {
        this.getConfig(false);
      });
  }

  saveConfig()
  {
    this.setState(prevState => {
       return {prevState: prevState}
    });

    garageApi.endpoints.site.update(this.state.web)
      .then((getRequest) => {
        try
        {
          console.log('saved');
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  updateText(...args)
  { // eslint-disable-next-line
    this.state.web[args[0]] = args[1];
    this.setState({ update: true });
  }

  uploadFile(event)
  {
    this.setState({ loadingDialog: true });
    const key = event.target.getAttribute('unikey');
    const formData = new FormData();

    // Update the formData object
    formData.append("image", event.target.files[0]);
    formData.append('type', 'web');
    formData.append('name', 'web');
    formData.append('unikey', uuid.v4());

    garageApi.endpoints.image.create(formData)
      .then((getRequest) => {
        try
        { // eslint-disable-next-line
          this.state.web[key] = getRequest.data.id;
          this.setState({ [key] : event.target.files[0].name})
          this.setState({ loadingDialog: false });
        }
        catch(e)
        {
          console.log(e);
        }
      });
  };

  render() {
    return (
      <SecuredZone
        breadcrumbs={{ '/admin/': 'Dashboard','/admin/site': 'Allgemein' }}
        menu={{ 'Speichern': this.saveConfig.bind(this)}}
        title="Allgemein"
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
      >
        <Dialog open={this.state.loadingDialog}>
          <div className="dialog-loading">
            <CircularProgress />
          </div>
        </Dialog>
        <div className="grid-section">
          <div className="settings-title">Öffnungs Zeiten</div>
          <div style={{ marginBottom: '50px' }}>
          { this.state.loading ?
            ''
            :
            <OpeningHours updateSelect={this.updateSelect.bind(this)} days={this.days} fieldName={this.fieldName} data={this.state.web.openingHours} />
          }
          </div>
          <div className="settings-title">Startseite</div>
          <div className="site-line-input">
            <TextField
              style={{ backgroundColor: 'white' }}
              autoFocus
              margin="dense"
              id="homeWelcomeTitle"
              label="Titel"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.web.homeWelcomeTitle}
              onInput={linkState(this, 'web.homeWelcomeTitle')}
            />
          </div>
          <div className="site-line-input">
            <TextField
              style={{ backgroundColor: 'white' }}
              autoFocus
              margin="dense"
              id="homeWelcomeTitle"
              label="Titel"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.web.homeWriter}
              onInput={linkState(this, 'web.homeWriter')}
            />
          </div>
          <div className="site-line-text">
            <div className="settings-subtitle">Startseite Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.homeWelcomeText} onChange={this.updateText.bind(this,'homeWelcomeText')} />
          </div>

          <div style={{ marginTop: '60px' }}  className="settings-title">Standort</div>
          <div className="site-line-input">
            <TextField
              style={{ backgroundColor: 'white' }}
              autoFocus
              margin="dense"
              id="garageLocation"
              label="Standort"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.web.garageLocation}
              onInput={linkState(this, 'web.garageLocation')}
            />
          </div>

          <div style={{ marginTop: '60px' }} className="settings-title">Kontakt</div>
          <div className="site-line-text">
            <div className="settings-subtitle">Kontakt Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.contactText} onChange={this.updateText.bind(this,'contactText')} />
          </div>

          <div style={{ marginTop: '60px' }} className="settings-title">Footer</div>
          <div className="site-line-text">
            <div className="settings-subtitle">Footer Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.footerText} onChange={this.updateText.bind(this,'footerText')} />

            <div className="settings-subtitle">Partner Text</div>
            <ReactQuill ref={this.partnerImage} modules={this.state.modulesImage} style={{ backgroundColor: 'white' }} value={this.state.web.partnerText} onChange={this.updateText.bind(this,'partnerText')} />
          </div>

          <div style={{ marginTop: '60px' }} className="settings-title">Online Termin</div>
          <div className="site-line-text">
            <div className="settings-subtitle">Termin Zeit Vorschlags Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.appointmentSuggestionText} onChange={this.updateText.bind(this,'appointmentSuggestionText')} />

            <div className="settings-subtitle">Zusammenfassungs Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.appointmentSummaryText} onChange={this.updateText.bind(this,'appointmentSummaryText')} />

            <div className="settings-subtitle">Dankes Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.appointmentThanksText} onChange={this.updateText.bind(this,'appointmentThanksText')} />
          </div>

          <div style={{ marginTop: '60px' }} className="settings-title">Garage</div>
          <div className="site-line-text">
            <div className="settings-subtitle">Über uns Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.garageAboutText} onChange={this.updateText.bind(this,'garageAboutText')} />
          </div>

          <div style={{ marginTop: '60px' }} className="settings-title">PopUp Box</div>
          <div className="site-line-text">
            <div className="site-line-input">
              <TextField
                style={{ backgroundColor: 'white' }}
                margin="dense"
                id="mapsLink"
                label="Karten Link"
                type="text"
                fullWidth
                variant="outlined"
                value={this.state.web.mapsLink}
                onInput={linkState(this, 'web.mapsLink')}
              />
            </div>
            <div className="settings-subtitle">Karten Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.mapsLinkText} onChange={this.updateText.bind(this,'mapsLinkText')} />
            <div className="settings-subtitle">Kontakt Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.popUpcontactText} onChange={this.updateText.bind(this,'popUpcontactText')} />
          </div>

          <div style={{ marginTop: '60px' }} className="settings-title">Impressum</div>
          <div className="site-line-text">
            <div className="settings-subtitle">Impressum Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.impressumText} onChange={this.updateText.bind(this,'impressumText')} />
          </div>

          <div style={{ marginTop: '60px' }} className="settings-title">Datenschutz</div>
          <div className="site-line-text">
            <div className="settings-subtitle">Datenschutz Text</div>
            <ReactQuill modules={this.state.modules} style={{ backgroundColor: 'white' }} value={this.state.web.dataProtectionText} onChange={this.updateText.bind(this,'dataProtectionText')} />
          </div>

          <div style={{ marginTop: '60px' }} className="settings-title">Bilder</div>
          <div className="site-line-text">

            <div className="settings-subtitle">Startseite</div>
            <div className="settings-image-upload">
              <div className="image-upload">
                <div className="image-upload-display">{this.state.homeImage}</div>
                <input unikey='homeImage' className="image-upload-input" type="file" onChange={this.uploadFile.bind(this)} />
              </div>
            </div>

            <div className="settings-subtitle">Dienstleistungen</div>
            <div  className="settings-image-upload">
              <div className="image-upload">
                <div className="image-upload-display">{this.state.servicesImage}</div>
                <input unikey='servicesImage' className="image-upload-input" type="file" onChange={this.uploadFile.bind(this)} />
              </div>
            </div>

            <div className="settings-subtitle">Nachrichten</div>
            <div  className="settings-image-upload">
              <div className="image-upload">
                <div className="image-upload-display">{this.state.newsImage}</div>
                <input unikey='newsImage' className="image-upload-input" type="file" onChange={this.uploadFile.bind(this)} />
              </div>
            </div>

            <div className="settings-subtitle">Online Termin</div>
            <div  className="settings-image-upload">
              <div className="image-upload">
                <div className="image-upload-display">{this.state.appointmentImage}</div>
                <input unikey='appointmentImage' className="image-upload-input" type="file" onChange={this.uploadFile.bind(this)} />
              </div>
            </div>

            <div className="settings-subtitle">Impressum</div>
            <div  className="settings-image-upload">
              <div className="image-upload">
                <div className="image-upload-display">{this.state.impressumImage}</div>
                <input unikey='impressumImage' className="image-upload-input" type="file" onChange={this.uploadFile.bind(this)} />
              </div>
            </div>

            <div className="settings-subtitle">Datenschutz</div>
            <div  className="settings-image-upload">
              <div className="image-upload">
                <div className="image-upload-display">{this.state.dataProtectionImage}</div>
                <input unikey='dataProtectionImage' className="image-upload-input" type="file" onChange={this.uploadFile.bind(this)} />
              </div>
            </div>

          </div>
        </div>
      </SecuredZone>
    );
  }
}


export default AdminSite;
