import axios from 'axios';

axios.defaults.baseURL = 'https://api.sportplatzgarage.ch/';

class API {
  constructor()
  {
    this.endpoints = {};
    this.endpoints.user = this.createBasicCRUDEndpoints({ name: 'user' });
    this.endpoints.posts = this.createBasicCRUDEndpoints({ name: 'posts' });
    this.endpoints.services = this.createBasicCRUDEndpoints({ name: 'services' });
    this.endpoints.employees = this.createBasicCRUDEndpoints({ name: 'employees' });
    this.endpoints.site = this.createBasicCRUDEndpoints({ name: 'site' });
    this.endpoints.image = this.createBasicCRUDEndpoints({ name: 'image' });
    this.endpoints.appointmentServices = this.createBasicCRUDEndpoints({ name: 'appointment/services' });
    this.endpoints.appointmentChecks = this.createBasicCRUDEndpoints({ name: 'appointment/checks' });
    this.endpoints.formAppointments = this.createBasicCRUDEndpoints({ name: 'form/appointment' });
    this.endpoints.formContacts = this.createBasicCRUDEndpoints({ name: 'form/contact' });

    this.openEndpoints = {};
    this.openEndpoints.site = this.createBasicCRUDOpenEndpoints({ name: 'site' });
    this.openEndpoints.services = this.createBasicCRUDOpenEndpoints({ name: 'services' });
    this.openEndpoints.posts = this.createBasicCRUDOpenEndpoints({ name: 'posts' });
    this.openEndpoints.postsImages = this.createBasicCRUDOpenEndpoints({ name: 'posts/images' });
    this.openEndpoints.employees = this.createBasicCRUDOpenEndpoints({ name: 'employees' });
    this.openEndpoints.appointmentServices = this.createBasicCRUDOpenEndpoints({ name: 'appointment/services' });
    this.openEndpoints.appointmentChecks = this.createBasicCRUDOpenEndpoints({ name: 'appointment/checks' });
    this.openEndpoints.formAppointment = this.createBasicCRUDOpenEndpoints({ name: 'form/appointment' });
    this.openEndpoints.formContact = this.createBasicCRUDOpenEndpoints({ name: 'form/contact' });
  }

  createBasicCRUDOpenEndpoints = ({ name }) => {
    const openEndpoints = {};

    const resourceURL = `v1/${name}`;

    openEndpoints.getAll = (config = { crossDomain: true }) =>  axios.get(`${resourceURL}/`, config).catch(err => console.log('error'));
    openEndpoints.getPage = ({ get }, config = { crossDomain: true, params: get}) =>  axios.get(`${resourceURL}/`, config).catch(err => console.log('error'));
    openEndpoints.getOne = ({ id }, config = { crossDomain: true }) => axios.get(`${resourceURL}/${id}/`, config).catch(err => console.log('error'));
    openEndpoints.create = (toCreate, config = { crossDomain: true }) => axios.post(`${resourceURL}/`, toCreate, config).catch(err => console.log('error'));

    return openEndpoints;
  }

  createBasicCRUDEndpoints = ({ name }) => {

    var authToken = '';

    if (localStorage.getItem('token') !== null)
    {
      authToken = localStorage.getItem('token');
    }

    const endpoints = {};

    const resourceURL = `v1/admin/${name}`;

    endpoints.getAll = (config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) =>  axios.get(`${resourceURL}/`, config).catch(err => console.log('error'));
    endpoints.getOne = ({ id }, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.get(`${resourceURL}/${id}/`, config).catch(err => console.log('error'));
    endpoints.getPage = ({ get }, config = { crossDomain: true, params: get, headers: { 'Authorization': ` Token ${authToken}` }}) =>  axios.get(`${resourceURL}/`, config).catch(err => console.log('error'));
    endpoints.create = (toCreate, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.post(`${resourceURL}/`, toCreate, config).catch(err => console.log('error'));
    endpoints.update = (toUpdate, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.put(`${resourceURL}/${toUpdate.id}/`, toUpdate, config).catch(err => console.log('error'));
    endpoints.patch = ({ id }, toPatch, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.patch(`${resourceURL}/${id}/`, toPatch, config).catch(err => console.log('error'));
    endpoints.delete = ({ id }, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.delete(`${resourceURL}/${id}/`, config).catch(err => console.log('error'));

    return endpoints;
  }
}

export default API;
