import React, { useRef, useEffect } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import mapboxgl  from '!mapbox-gl'  // eslint-disable-line import/no-webpack-loader-syntax
import { useLocation } from 'react-router-dom'
import EmployeesSection from "./Sections/EmployeesSection";
import PartnerSection from "./Sections/PartnerSection";
import PopOutInfo from "../../components/PopOutInfo/PopOutInfo";
import {Helmet} from "react-helmet";

export default function GaragePage(props) {
  const location = useLocation();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const { configuration } = props;

  useEffect(() => {

    map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/light-v10',
        center: getLocations(configuration.garageLocation),
        scrollZoom: false,
        boxZoom: false,
        dragRotate: false,
        dragPan: true,
        keyboard: false,
        doubleClickZoom: false,
        touchZoomRotate: false,
        zoom: 16,
      });

    map.current.on("wheel", event => {
      if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
        event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
        map.current.scrollZoom.enable();
      } else {
        map.current.scrollZoom.disable();
      }
    });

    map.current.on('touchstart', event => {
        if ((event.originalEvent && 'touches' in event.originalEvent && event.originalEvent.touches.length >= 2)) {
          map.current.dragPan.enable();
        } else {
          map.current.dragPan.disable();
        }
    });

    new mapboxgl.Marker()
      .setLngLat(getLocations(configuration.garageLocation))
      .addTo(map.current);

  }, [configuration.garageLocation]);

  function getLocations(location) {
    var locSplit = location.split(',');

    locSplit[0] = parseFloat(locSplit[0]);
    locSplit[1] = parseFloat(locSplit[1]);

    return locSplit;
  }

  mapboxgl.accessToken = 'pk.eyJ1IjoiZ3Jpc29mdCIsImEiOiJja2g2Y3pneTcwcXB4MnFuc2ZiazN5NDZjIn0.tsgWmDl5DwREXrDhXjecBQ';

  return (
    <div>
      <Helmet>
          <title>Sportplatzgarage - Garage</title>
      </Helmet>
      <PopOutInfo
        openingHours={configuration.openingHours}
        mapsLink={configuration.mapsLink}
        mapsLinkText={configuration.mapsLinkText}
        popUpcontactText={configuration.popUpcontactText}
      />
      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <div ref={mapContainer} className="mapbox-container">
        </div>
        <div className="page-content">
          <EmployeesSection garageAboutText={configuration.garageAboutText}/>
          <PartnerSection partnerText={configuration.partnerText}/>
        </div>
        <Footer footerText={configuration.footerText} partnerText={configuration.partnerText} />
      </div>
    </div>
  );
}
