import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import ParallaxCache from "../../components/Parallax/ParallaxCache";
import { ParallaxBanner  } from 'react-scroll-parallax';
import { useLocation } from 'react-router-dom'
import PostSection from "./Sections/PostSection";
import PopOutInfo from "../../components/PopOutInfo/PopOutInfo";
import {Helmet} from "react-helmet";
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
//api
import API from '../../api/api';

const garageApi = new API();


export default function NewsPage(props) {

  const location = useLocation();
  const { configuration } = props;
  const [ images, setImages] = useState({});
  const [ loadingImages, setLoadingImages] = useState(false);

  useEffect(() => {

    garageApi.openEndpoints.postsImages.getAll()
      .then((imagesGET) => {
        try
        {
          setImages(imagesGET.data.results);
          setLoadingImages(true);
        }
        catch(e)
        {

        }

      });

  }, []);

  const properties = {
    duration: 4000,
    transitionDuration: 1000,
    infinite: true,
    pauseOnHover: false,
    prevArrow: <div className="news-slider-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#5a5a5a"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></div>,
    nextArrow: <div className="news-slider-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#5a5a5a"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></div>
  };

  return (
    <div>
      <Helmet>
          <title>Sportplatzgarage - Nachrichten</title>
      </Helmet>
      <PopOutInfo
        openingHours={configuration.openingHours}
        mapsLink={configuration.mapsLink}
        mapsLinkText={configuration.mapsLinkText}
        popUpcontactText={configuration.popUpcontactText}
      />
      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <div className="news-slider">
        { loadingImages ?
          <Slide {...properties} className="news-slider-container">
            {
              images.map((each, index) =>
              <div key={index} className="slider-inner-container">
                  <img draggable="false" alt={each.title} className="news-slider-image" src={each.image.image.header}/>
                  <div className="slider-title-container">
                    <div className="slider-title">{each.title}</div>
                  </div>
              </div>
              )
            }
          </Slide>
          :
          <ParallaxBanner
              className="open-parralax-container"
              layers={[
                  {
                      image: ( configuration.newsImage ? configuration.newsImage.image.header  : ''),
                      amount: 0.5,
                  }
              ]}
              style={{
                  height: '100%',
              }}
          >
              <div className="open-parralax">
                <div className="open-parralax-content">
                  <div className="open-parralax-content-title">Dienstleistungen</div>
                </div>
              </div>
              <ParallaxCache />
          </ParallaxBanner>
        }
        </div>
        <div className="page-content">
          <PostSection/>
        </div>
        <Footer footerText={configuration.footerText} partnerText={configuration.partnerText} />
      </div>
    </div>
  );
}
