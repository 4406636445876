import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import uuid from "uuid";
import API from '../../api/api';
import linkState from 'linkstate';
import './admin-employees.css';
import LinearProgress from '@material-ui/core/LinearProgress';


const garageApi = new API();

class AdminEmployeesDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      employee: {
        unikey: '',
        name: '',
        position: '',
        job: '',
        email: '',
        phone: '',
        description: '',
        image: [],
      },
      loading: true,
      selectedFile: null,
      upload: 'Bild Hochladen',
    };

    if(Object.keys(props.employeesData).length >=1)
    {
      this.isAddMode = false;
      this.state.employee = props.employeesData;
      this.state.loading = false;
    }
    else
    {
      this.state.employee.unikey = uuid.v4();
      this.state.loading = false;
    }
  }

  uploadFile = (event) => {

    const formData = new FormData();

    // Update the formData object
    formData.append("image", event.target.files[0]);
    formData.append('type', 'employee');
    formData.append('name', 'Mitarbeiter');
    formData.append('unikey', uuid.v4());

    garageApi.endpoints.image.create(formData)
      .then((getRequest) => {
        try
        { // eslint-disable-next-line
          this.state.employee.image = getRequest.data.id;
          this.setState({ upload: event.target.files[0].name})
        }
        catch(e)
        {
          console.log(e);
        }
      });
  };

  componentDidMount()
  {
  }

  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Mitarbeiter { this.isAddMode ? "Erstellen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="pt-line-one">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.employee.name}
              onInput={linkState(this, 'employee.name')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="position"
              label="Position"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.employee.position}
              onInput={linkState(this, 'employee.position')}
            />
          </div>
          <div className="pt-line-one">
            <TextField
              autoFocus
              margin="dense"
              id="job"
              label="Beruf"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.employee.job}
              onInput={linkState(this, 'employee.job')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="phone"
              label="Telefon"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.employee.phone}
              onInput={linkState(this, 'employee.phone')}
            />
          </div>
          <div className="pt-line-two">
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="E-Mail"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.employee.email}
              onInput={linkState(this, 'employee.email')}
            />
          </div>
          <div className="pt-line-two">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Beschreibung"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.employee.description}
              onInput={linkState(this, 'employee.description')}
            />
          </div>
          <div className="pt-line-three">
            <span></span>
            <div className="image-upload">
              <div className="image-upload-display">{this.state.upload}</div>
              <input className="image-upload-input" type="file" onChange={this.uploadFile} />
            </div>
            <span></span>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="p-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.props.submit(this.isAddMode,this.state.employee) }}>
              Speichern
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }
}

export default AdminEmployeesDialog;
