import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import uuid from "uuid";
import API from '../../api/api';
import linkState from 'linkstate';
import './admin-posts.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const garageApi = new API();

class AdminPostsDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      post: {
        unikey: '',
        title: '',
        description: '',
        text: '',
        image: 0,
      },
      loading: true,
      selectedFile: null,
      upload: 'Anzeige Bild Hochladen',
      snackBar: false,
      modules: {
          toolbar: {
            container: [
              [{ 'header': [1, 2, 3, false] }],
              ['bold', 'italic', 'underline','strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
              [{ 'align': [] }],
              ['link', 'image'],
              ['clean'],
             ],
             handlers: {
               image: this.editorUploadImage.bind(this)
             },
          },
        },
    };

    if(Object.keys(props.postsData).length >=1)
    {
      this.isAddMode = false;
      this.state.post = props.postsData;
      this.state.loading = false;
    }
    else
    {
      this.state.post.unikey = uuid.v4();
      this.state.loading = false;
    }

    this.quillObj = React.createRef();
  }

  uploadFile = (event) => {

    const formData = new FormData();

    // Update the formData object
    formData.append("image", event.target.files[0]);
    formData.append('type', 'post');
    formData.append('name', 'Service');
    formData.append('unikey', uuid.v4());

    garageApi.endpoints.image.create(formData)
      .then((getRequest) => {
        try
        { // eslint-disable-next-line
          this.state.post.image = getRequest.data.id;
          this.setState({ upload: event.target.files[0].name})
        }
        catch(e)
        {
          console.log(e);
        }
      });
  };


  editorUploadImage() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () =>
    {
      var file = input.files[0];
      var formData = new FormData();
      // Update the formData object
      formData.append("image", file);
      formData.append('type', 'default');
      formData.append('name', 'Service-Image');
      formData.append('unikey', uuid.v4());

      garageApi.endpoints.image.create(formData)
        .then((getRequest) => {
          try
          {
            var rangeIndex = 0;
            var range = this.quillObj.current.getEditorSelection();
            if(range)
            {
              rangeIndex = range.index;
            }

            this.quillObj.current.getEditor().insertEmbed(rangeIndex, 'image', getRequest.data.image['full']);
          }
          catch(e)
          {
            console.log(e);
          }
        });
    };
  }

  updateText(text)
  { // eslint-disable-next-line
    this.state.post.text = text;
  }

  submitDialog()
  {
    var postData = this.state.post;

    if( postData.title === '' || postData.description === '' || postData.text === '' || postData.image ===  0)
    {
      this.setState({ snackBar: true, });
    }
    else
    {
      this.props.submit(this.isAddMode,postData);
    }
  }

  closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackBar: false, });
  }


  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Post { this.isAddMode ? "Erstellen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="ser-line-one">
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Titel"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.post.title}
              onInput={linkState(this, 'post.title')}
            />
          </div>
          <div className="ser-line-one">
            <TextField
              autoFocus
              margin="dense"
              id="description"
              multiline
              rows={4}
              label="Beschreibung"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.post.description}
              onInput={linkState(this, 'post.description')}
            />
          </div>
          <div className="ser-line-two">
            <ReactQuill ref={this.quillObj} modules={this.state.modules} value={this.state.post.text} onChange={this.updateText.bind(this)} />
          </div>
          <div className="pt-line-three">
            <span></span>
            <div className="image-upload">
              <div className="image-upload-display">{this.state.upload}</div>
              <input className="image-upload-input" type="file" onChange={this.uploadFile} />
            </div>
            <span></span>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="p-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.submitDialog() }}>
              Speichern
            </Button>
          </div>
        </DialogActions>
        <Snackbar open={this.state.snackBar} autoHideDuration={6000} onClose={this.closeSnackBar.bind(this)}>
          <MuiAlert elevation={6} variant="filled" onClose={this.closeSnackBar.bind(this)} severity="warning" >
            Alle Felder müssen ausgefüllt sein!
          </MuiAlert>
        </Snackbar>
      </div>
    );
  }
}

export default AdminPostsDialog;
