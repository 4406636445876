import './assets/css/main.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Fragment, useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import GaragePage from './views/GaragePage/GaragePage';
import NewsPage from './views/NewsPage/NewsPage';
import NewsOnePage from './views/NewsOnePage/NewsOnePage';
import ServicesPage from './views/ServicesPage/ServicesPage';
import HomePage from './views/HomePage/HomePage';
import AdminDashboard from './views/Admin/Dashboard/Dashboard';
import AdminSite from './views/Admin/Site/Site';
import AdminAppointment from './views/Admin/Appointment/Appointment';
import AdminPosts from './views/Admin/Posts/Posts';
import AdminServices from './views/Admin/Services/Services';
import AdminEmployees from './views/Admin/Employees/Employees';
import AdminForms from './views/Admin/Forms/Forms';
import AdminSettings from './views/Admin/Settings/Settings';
import OnlineTerminPage from './views/OnlineTerminPage/OnlineTerminPage';
import ImpressumPage from './views/ImpressumPage/ImpressumPage';
import DataProtectionPage from './views/DataProtectionPage/DataProtectionPage';
import AuthLogin from './views/Auth/Login';
import AuthLogout from './views/Auth/Logout';
import useAuth from './views/Auth/Auth';
import CookieConsent from "react-cookie-consent";
import { AnimatePresence, motion} from "framer-motion";
import { ParallaxProvider } from 'react-scroll-parallax';
import API from './api/api';
import Dialog from '@material-ui/core/Dialog';
import useAckee from 'use-ackee'

const garageApi = new API();

function App() {

  const location = useLocation();

	useAckee(location.pathname, {
		server: 'https://analytics.grischa.io',
		domainId: 'f5a4740f-4452-42c9-a3a5-bb339f406998'
	}, {
		ignoreLocalhost: false,
    detailed: true,
	})

  const [configuration, setConfiguration] = useState({});
  const [configurationload, setConfigurationLoad] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  const products = {
    initial: { y: -100, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.25,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  useEffect(() => {
    garageApi.openEndpoints.site.getAll()
      .then((getRequest) => {
        try
        {
          if(Object.keys(getRequest.data.results).length === 0)
          {
          } else {
            setConfiguration(getRequest.data.results[0]);
            setConfigurationLoad(true);
          }

        }
        catch(e)
        {
          setErrorDialog(true);
        }
      });
  }, []);

  return (
    <ParallaxProvider>
      <Dialog fullScreen open={errorDialog}>
        <div className="error-text">
          Site Configuration Error
        </div>
      </Dialog>
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        cookieName="acceptCookies"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        >
        Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.
      </CookieConsent>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute exact path="/admin/" component={AdminDashboard}/>
          <PrivateRoute exact path="/admin/site" component={AdminSite}/>
          <PrivateRoute exact path="/admin/posts" component={AdminPosts}/>
          <PrivateRoute exact path="/admin/services" component={AdminServices}/>
          <PrivateRoute exact path="/admin/employees" component={AdminEmployees}/>
          <PrivateRoute exact path="/admin/appointment" component={AdminAppointment}/>
          <PrivateRoute exact path="/admin/settings" component={AdminSettings}/>
          <PrivateRoute exact path="/admin/logout" component={AuthLogout}/>
          <PrivateRoute exact path="/admin/forms" component={AdminForms}/>

          <PublicRoute exact path="/impressum">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <ImpressumPage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute exact path="/datenschutz">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <DataProtectionPage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute exact path="/admin/login">
            <motion.section exit={{ opacity: 0 }}>
              <motion.div initial="initial" animate="animate" variants={products}>
                  <AuthLogin/>
              </motion.div>
            </motion.section>
          </PublicRoute>
          <PublicRoute exact path="/garage">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <GaragePage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute exact path="/nachrichten">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <NewsPage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute path="/nachrichten/:id">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <NewsOnePage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute path="/dienstleistungen">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <ServicesPage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute exact path="/online-termin">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <OnlineTerminPage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute exact path="/">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <HomePage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>

          <ErrorRoute path="*"/>
        </Switch>
      </AnimatePresence>
    </ParallaxProvider>
  );
}


function PrivateRoute({ ...rest })
{
  const auth = useAuth()
  return (
    <>
      {
        auth.isAuth ?
        (<Route {...rest}/>)
        :
        (<Redirect to="/admin/login" />)
      }
    </>
  )
}

function PublicRoute({ children, ...rest })
{
  return (
    <Route {...rest} render={({ location }) =>
        (children)
      }
    />
  )
}

function ErrorRoute({ children, ...rest })
{
  const auth = useAuth()
  return (
    <Route {...rest} render={({ location }) =>
        auth.isAuth ?
        (<Redirect to="/admin/" />)
        :
        (<Redirect to="/" />)
      }
    />
  )
}

export default App;
